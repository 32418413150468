import { Helmet, HelmetProvider } from "react-helmet-async";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

export default function SEO({ title, description }) {
  const location = useLocation();

  const pageData = {
    title: `${title}`,
    description: `${description}`,
    keywords:
      "Tutorpoint, homework help, best Homework Assistance platform, best quality, homework assistance, Free quote, Expert help, Top-rated, 24/7 support, assignment help, assignment writing service, assignment help online, assignment services, assignment help expert, India Assignment Help",
    Url: "https://tutorpoint.in/ ", // Provide the actual URL here
    canonicalUrl: `https://tutorpoint.in${location.pathname}`,
    SiteName: "Tutorpoint",
    Locale: "en_US",
    Type: "article", // Change to "article" for specific article-type pages
    ogUrl: "https://tutorpoint.in/ ", // Provide the actual URL here
    ogSiteName: "Tutorpoint",
    ogLocale: "en_US",
    ogType: "article", // Change to "article" for specific article-type pages
    openGraph: {
      type: "website",
      keywords:
        "Tutorpoint, best Homework Assistance platform, homework help, best quality, homework assistance, Free quote, Expert help, Top-rated, 24/7 support, assignment help, assignment writing service, assignment help online, assignment services, assignment help expert, India Assignment Help",
      Url: "https://tutorpoint.in/ ", // Provide the actual URL here
      SiteName: "Tutorpoint",
      Locale: "en_US",
      Type: "article", // Change to "article" for specific article-type pages
    },
  };

  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        {/* <title>{title} - 24X7 Online Homework Assistance at Tutorpoint</title> */}
        {/* <title>24X7 Online Homework Assistance at Tutorpoint</title> */}
        <title>{title}</title>
        <meta name="title" content={title || pageData.title} />
        <meta
          name="description"
          content={description || pageData.description}
        />
        <meta name="robots" content="index, follow" />
        <meta
          name="keywords"
          content="Genius Tutor, homework assistance, Free quote, Expert help, Top-rated, 24/7 support, assignment help, assignment writing service, assignment help online, assignment services, assignment help expert, India Assignment Help"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        {/* <meta property="og:title" content={pageData.title} />
        <meta property="og:description" content={pageData.description} /> */}
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <link rel="canonical" href={pageData.canonicalUrl} />
        {/* <link rel="canonical" href="https://thegeniustutors.com/ " /> */}
        <meta property="og:url" content={pageData.ogUrl} />
        <meta property="og:site_name" content={pageData.ogSiteName} />
        <meta property="og:locale" content={pageData.ogLocale} />
        <meta property="og:type" content={pageData.ogType} />
        <meta property="fb:app_id" content={pageData.fbAppId} />
        <meta name="language" content="English" />
      </Helmet>
    </HelmetProvider>
  );
}
