import { createClient } from "@sanity/client";

const client = createClient({
  projectId: "btxim9v1",
  dataset: "production",
  useCdn: true,
  apiVersion: "2023-09-09",
});

export default client;
