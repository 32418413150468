import React, { useRef } from "react";
import FooterData from "../components/Footer/FooterData";
import ChatIntegration from "../components/ChatIntegration";
import ChatFeatures from "../components/Features/ChatFeatures";
import Service from "../components/Service/Service";
import ChatBanner from "../components/Banner/ChatBanner";
import CustomNavbar from "../components/CustomNavbar";
import FooterTwo from "../components/Footer/FooterTwo";
import FeaturesCard from "../components/card/FeaturesCard";
import { options } from "../data/data";
import { useLocation, useParams } from "react-router-dom/cjs/react-router-dom";
import BannerPart from "../components/Banner/BannerPart";
import review from "../dummy/reviews.webp";
import trustPilot from "../dummy/trustpilot.webp";
import siteJabber from "../dummy/sidejabbar.webp";
import image from "../dummy/why-we-need.webp";
import SEO from "../components/SEO";

const ServiceDetails = () => {
  const homeRef = useRef(null);
  const serviceRef = useRef(null);
  const featureRef = useRef(null);
  const pricingRef = useRef(null);
  const reviewRef = useRef(null);
  const onClickHome = () => {
    if (homeRef.current) {
      homeRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  
  const onClickService = () => {
    if (serviceRef.current) {
      serviceRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  
  const onClickFeatures = () => {
    if (featureRef.current) {
      featureRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  
  const onClickPricing = () => {
    if (pricingRef.current) {
      pricingRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  
  const onClickReview = () => {
    if (reviewRef.current) {
      reviewRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const { serviceName } = useParams();
  // console.log(serviceName, ": service name");

  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  const formatRouteParam = (serviceName) => {
    return serviceName.replace(/-/g, " ").replace(/%20/g, " ");
  };
  // console.log(location, "locations");
  // Filter FAQ based on the current service label
  //   const filteredFaq = faq.filter((item) => item.label === serviceName);
  // console.log(filteredFaq, "filterdFAQ");
  const filteredOptions = options.filter(
    (service) =>
      // service.label === formatRouteParam(serviceName)
      service.label === formatRouteParam(serviceName)
  );
  // console.log(filteredOptions, "filtered options");

  const currentServiceOption = filteredOptions[0];
  // console.log(currentServiceOption, "current service option");

  const title = currentServiceOption?.seoTitle || "Default SEO Title";
  const desc =
    currentServiceOption?.seoDescription || "Default SEO Description";

  console.log(title, "seo title");
  console.log(desc, "seoDescription");

  const aboutImage = currentServiceOption?.aboutImage || ""; // Assuming you have an aboutImage property in your options
  // console.log(aboutImage, "aboutImage");
  const bannerTitle =
    currentServiceOption?.bannerTitle || `${formatRouteParam(serviceName)}`; // Assuming you have an aboutImage property in your options
  const bannerDesc =
    currentServiceOption?.bannerDesc ||
    "Revolutionize your career with online homework \n help available today!"; // Assuming you have an aboutImage property in your options
  const whyTitle =
    currentServiceOption?.whyTitle || "Why you need this particular section";
  const aboutTitle = currentServiceOption?.aboutTitle || (
    <>
      Genius Tutors{" "}
      <span className="capitalize">{formatRouteParam(serviceName)}</span> - One
      place for best quality{" "}
      <span className="capitalize">{formatRouteParam(serviceName)}</span>
    </>
  );
  const howWorksTitle = currentServiceOption?.howWorksTitle || (
    <>How {formatRouteParam(serviceName)} works</>
  );

  return (
    <div className="body_wrapper">
      <SEO title={title} description={desc} />
      <CustomNavbar
        nClass="m-auto"
        hbtnClass="new_btn"
        onClickHome={onClickHome}
        onClickService={onClickService}
        onClickFeatures={onClickFeatures}
        onClickPricing={onClickPricing}
        onClickReview={onClickReview}
      />
      <div className="pt-[140px]">
        <div className="justify-between bg-white flex flex-col pb-10">
          <div className="banner-section bg-[#fff9f9] py-16 pt-2">
            <div className="mx-auto max-w-screen-xl px-6">
              <div className="mx-auto max-w-screen-xl">
                {/* <Breadcrumb /> */}
              </div>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-4">
                <BannerPart
                  title={bannerTitle}
                  desc={bannerDesc}
                  review={review}
                  trustPilot={trustPilot}
                  siteJabber={siteJabber}
                />
                <div>
                  <div className="rounded-lg bg-white p-8 shadow-lg lg:col-span-3 lg:p-12">
                    <form action="#" className="space-y-4">
                      <div>
                        <label className="sr-only" htmlFor="name">
                          Name
                        </label>
                        <input
                          className="w-full rounded-lg border p-3 text-sm"
                          placeholder="Name"
                          type="text"
                          id="name"
                        />
                      </div>

                      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                        <div>
                          <label className="sr-only" htmlFor="email">
                            Email
                          </label>
                          <input
                            className="w-full rounded-lg border p-3 text-sm"
                            placeholder="Email address"
                            type="email"
                            id="email"
                          />
                        </div>

                        <div>
                          <label className="sr-only" htmlFor="phone">
                            Phone
                          </label>
                          <input
                            className="w-full rounded-lg border p-3 text-sm"
                            placeholder="Phone Number"
                            type="tel"
                            id="phone"
                          />
                        </div>
                      </div>

                      

                      <div>
                        <label className="sr-only" htmlFor="message">
                          Message
                        </label>

                        <textarea
                          className="w-full rounded-lg border p-3 text-sm"
                          placeholder="Message"
                          rows="8"
                          id="message"
                        ></textarea>
                      </div>

                      <div className="mt-4">
                        <button
                          type="submit"
                          className="inline-block w-full rounded-lg bg-black px-5 py-3 font-medium text-white sm:w-auto"
                        >
                          Send Enquiry
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="service-about-section mx-auto py-16 max-w-screen-xl px-6">
            <div className="grid md:grid-cols-2 gap-5">
              <div className="content-part grid gap-3">
                <h4 className="text-2xl  font-semibold text-black">
                  {aboutTitle}
                </h4>
                {/* Inside the service-about-section mapping */}
                {options
                  .filter(
                    (service) => service.label === formatRouteParam(serviceName)
                  )
                  .map((service, index) => {
                    // console.log("service", service); // Add this line
                    return (
                      <React.Fragment key={index}>
                        {service.serviceAboutSectionPara.map(
                          (para, paraIndex) => (
                            <p
                              key={paraIndex}
                              className="text-justify  leading-7 tracking-wide"
                            >
                              {para}
                            </p>
                          )
                        )}
                      </React.Fragment>
                    );
                  })}
              </div>
              <div className="image-part">
                <img
                  src={aboutImage}
                  // src={aboutt}
                  alt="assignment"
                  title="homework"
                  width="100%"
                  height="100%"
                />
              </div>
            </div>
          </div>
          <div className="service-why-section mx-auto py-12 max-w-screen-xl px-6">
            <div className="grid md:grid-cols-2 gap-5">
              <div className="content-part grid gap-3">
                <h4 className="text-2xl  font-semibold text-black">
                  {whyTitle}
                </h4>
                {/* Inside the service-why-section mapping */}
                {options
                  .filter(
                    (service) => service.label === formatRouteParam(serviceName)
                  )
                  .map((service, index) => {
                    // console.log("service", service); // Add this line
                    return (
                      <React.Fragment key={index}>
                        {service.serviceWhySectionPara.map(
                          (para, paraIndex) => (
                            <p
                              key={paraIndex}
                              className="text-justify  leading-7 tracking-wide"
                            >
                              {para}
                            </p>
                          )
                        )}
                      </React.Fragment>
                    );
                  })}
              </div>
              <div className="image-part">
                <img
                  src={image}
                  alt="homework"
                  title="assignment"
                  width="100%"
                  height="100%"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ChatFeatures
        rowClass="flex-row-reverse"
        pClass="pr_60"
        col1="col-lg-6 offset-lg-1"
        col2="col-lg-5"
        imgClass="imgClass1"
        class3="img-contr"
        icon="dashboard.png"
        img1="croppedchat.gif"
        img2="support_dashboard.jpg"
        img3="skyp_2.png"
        titleSmall="Instant & Dedicated"
        titlebig="WhatsApp Support"
        p="Real-time tools and rich insights mean our support solution amplifies your team for an unbeatable experience."
        adv={true}
        url="#"
      />
      <ChatFeatures
        pClass="pl_70"
        col1="col-lg-6"
        col2="col-lg-5 offset-lg-1"
        icon="video_icon.png"
        imgClass="imgClass2"
        img1="googleMeet.png"
        img2="skyp_1.png"
        img3="online.png"
        titlebig="Video Calling Support"
        p="Real-time tools and rich insights mean our support solution amplifies your team for an unbeatable experience."
        url="#"
      />
      <ChatIntegration />
      <FooterTwo FooterData={FooterData} onClickService={onClickService} />
    </div>
  );
};

export default ServiceDetails;
