import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
// /*------ Pages-----*/
// import { Home } from "./Pages/Home";
// import DigitalMarketing from "./Pages/Digital-marketing";
// import PaymentProcessing from "./Pages/Payment-processing";
// import HRManagement from "./Pages/HR-Management";
// import Startup from "./Pages/Startup";
// import HomeCRM from "./Pages/Home-CRM";
import About from "./Pages/About";
import Privacy from "./Pages/Privacy";
import Terms from "./Pages/Terms";
import Refund from "./Pages/Refund";
// import AdminSignin from "./components/TutorDashboard/Pages/adminSignIn";
// import Admin from "./components/TutorDashboard/Pages/admin";
// import {TutDashPass} from "./components/TutorDashboard/Components/tutDashPass"
// import Service from "./Pages/Service";
// import Process from "./Pages/Process";
// import Team from "./Pages/Team";
// import Portfolio2col from "./Pages/Portfolio-2col";
// import Portfolio3col from "./Pages/Portfolio-3col";
// import Portfoliofull4col from "./Pages/Portfolio-fullwidth-4col";
// import PortfolioSingle from "./Pages/PortfolioSingle";
// import Bloglist from "./Pages/Bloglist";
// import BlogSingle from "./Pages/BlogSingle";
// import Contact from "./Pages/Contact";
import ScrollToTopRoute from "./ScrollToTopRoute";
// import Landing from "./Pages/Landing";
// import homesupport from "./Pages/home-support";
// import homeERP from "./Pages/Home-ERP";
// import homeHosting from "./Pages/HomeHosting";
// import homeSecurity from "./Pages/HomeSecurity";
// import homeSoftwareDark from "./Pages/Home-software-dark";
// import HomeAppShowcase from "./Pages/Home-app-showcase";
// import HomeCloud from "./Pages/Home-cloud";
// import HomeTracking from "./Pages/Home-tracking";
// import HomeEvent from "./Pages/Home-event";
import HomeChat from "./Pages/Home-chat";
// import Price from "./Pages/Price";
import Faq from "./Pages/Faq";
// import ServiceDetails from "./Pages/ServiceDetails";
// import SignIn from "./Pages/SignIn";
// import SignUp from "./Pages/SignUp";
// import TutorSignIn from "./Pages/TutorSignin";
// import TutorSignUp from "./Pages/TutorSignup";
import NotFound from "./Pages/404";
import ServiceDetails from "./Pages/ServiceDetails";
import Blog from "./Pages/Blog/Blog";
import PostDetails from "./Pages/Blog/PostDetails";
// import Dashboard from "./components/StudentDashboard/Pages/Dashboard_main";
// import TutorDashboard from "./components/TutorDashboard/Pages/Dashboard_main";
// import ForgetPass from "./Pages/ForgetPass";
// import Tutor_regForm from "./components/TutorDashboard/Components/RegistrationForm";
// import Tutor_exam from "./components/TutorDashboard/tutor_exam/tutorExamApp"
// import TutorSignup from "./"
// import { ResetPass } from "./components/ResetPassword/ResetPass";

let user = JSON.parse(localStorage.getItem("user"));
let tutor = JSON.parse(localStorage.getItem("tutor"));
let admin = JSON.parse(localStorage.getItem("admin"));

class App extends Component {
  componentDidMount() {
    // this.props.hideLoader();
  }
  render() {
    return (
      <Router>
        <Switch>
          <ScrollToTopRoute exact={true} path={"/"}>
            <HomeChat />
          </ScrollToTopRoute>

          <ScrollToTopRoute exact={true} path="/About" component={About} />
          <ScrollToTopRoute
            exact={true}
            path="/privacyPolicy"
            component={Privacy}
          />
          <ScrollToTopRoute
            exact={true}
            path="/termsConditions"
            component={Terms}
          />
          <ScrollToTopRoute exact={true} path="/Faq" component={Faq} />
          <ScrollToTopRoute
            exact={true}
            path="/refundPolicy"
            component={Refund}
          />
          <ScrollToTopRoute
            exact={true}
            path="/services/:serviceName"
            component={ServiceDetails}
          />
          <ScrollToTopRoute exact={true} path="/blogs" component={Blog} />
          <ScrollToTopRoute
            exact={true}
            path="/blogs/:slug"
            component={PostDetails}
          />

          {/* <ScrollToTopRoute exact={true} path="/SignIn" component={SignIn} /> */}
          {/* <ScrollToTopRoute exact={true} path="/forgot-pass" component={ForgetPass} /> */}
          {/* <ScrollToTopRoute exact={true} path="/SignUp" component={SignUp} /> */}
          {/* <ScrollToTopRoute exact={true} path="/reset-pwd/:id/:clientId" component={ResetPass} /> */}

          <ScrollToTopRoute component={NotFound} />
        </Switch>
      </Router>
    );
  }
}

export default App;
