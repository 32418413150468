import React from 'react';

const RefundSection = () => {
  return (
    <section className='faq_area bg_color '>
       <div style={{padding:'30px',backgroundColor:"whitesmoke"}}>
    <div style={{maxWidth:'1400px' , margin:'auto'}}>
    {/* <li style={{padding:'4px'}}><a  className="axil-btn btn-fill-primary btn-fluid btn-primary" style={{color:'white', fontSize:'20px'}} to={process.env.PUBLIC_URL + "/"}>Go to Home</a></li> */}
                <div>
                <h2>Refund Policy for Client</h2>
                <p>We understand that occasionally, situations may arise where a client may request a refund for the work that has been provided. We strive to maintain a fair and transparent approach to resolving such matters. This Refund policy outlines terms and conditions for requesting a refund as a client for services provided. Please carefully review the following information</p>
                </div>
                <div>
                <h2>Eligibility for refunds</h2>
                <p>The Client is eligible for Full/Partial/No refund based on conditions mentioned below:-</p>
                    <ul>
                      <h4>Full refund</h4>
                      <p>A full refund can be requested under following cases</p>
                      <li style={{padding:'4px'}}>If the delivered assignment does not meet the agreed-upon specifications and requirements as outlined in the initial assignment description or instruction provided.</li>
                      <li style={{padding:'4px'}}>If the assignment is failed to delivered within agreed-upon deadline.</li>
                      <li style={{padding:'4px'}}>If the client fails in the assignment/tasks.</li>
                    </ul>

                    <ul>
                      <h4>Partial Refund</h4>
                      <p>A Partial refund is can be requested under following cases</p>
                      <li style={{padding:'4px'}}>If the client decides to cancel the task before the deadline, a cancellation charge of 25% will be charged if the expert has not started the work. However, if the expert has started the work an additional amount will be charged based on the percentage of work completed by the expert.</li>
                      <li style={{padding:'4px'}}>If the client receives low score in the assignment, the client is entitled to partial refund of up-to 30% depending on how much lower from the agreed-upon marks the client has received</li>
                    </ul>

                    <ul>
                      <h4>No refund</h4>
                      <p>No refund will be provided to the client under the following cases</p>
                      <li style={{padding:'4px'}}>The request for refund is put more than 30days after the task delivery</li>
                      <li style={{padding:'4px'}}>If the client has already approved and accepted the task.</li>
                      <li style={{padding:'4px'}}>The client has used the delivered work or portion of it for their intended purposes</li>
                      <li style={{padding:'4px'}}>In case client feels that certain contents of the assignment contain plagiarism then we will revise the assignment to clear out any plagiarized content as pointed out by the client till the client’s satisfaction free of cost, since all the assignments are checked through various plagiarism-finding tools to bring plagiarism below the agreed-upon limit before they are dispatched to the client. We will not refund the money in these cases.</li>
                      <li style={{padding:'4px'}}>In case full payment for a task is done and client decide to cancel the task, no refund will be provided to the client in case no expert has been assigned but client can get any another work of same monetary value done without any additional cost.</li>
                      <li style={{padding:'4px'}}>A task is delayed due to last minute change requested by the client.</li>
                      <li style={{padding:'4px'}}>We are aimed to give the best solutions for the assignments and that's why we will keep reworking on the assignment to the point where the client becomes satisfied that the assignment meets the requirements. However, the reworks are done within one month (30 days) of the time frame. Any Rework requested post 30 days of task delivery will be treated as a new task and no refund will be provided for the said original task.</li>

                    </ul>
                </div>

                    <div>
                      <h2>Refund Process</h2>
                      <p> A refund can be requested by client with a proper reason and with certain proofs (Score card, Feedback mail) and refund will be provided to client within 15 days.</p>
                    </div>
              </div>
 
    </div>
    </section>
  );
};
export default RefundSection;
